import DriverRequest from '@requests/driver_request';
import OperationRequest from '@requests/operation_request';
import ReportRequest from '@requests/report_request';
import BranchRequest from '@requests/branch_request';
import OrderItemRequest from '@requests/order_item_request';

export default {
    download(_, filter) {
        ReportRequest.getDriversSummaryReport(filter);
    },
    pay({ dispatch }, { entity, pay }) {
        return new Promise((resolve) => {
            OrderItemRequest.updateItemPayment(entity.id, pay).then(() => {
                dispatch('index');
                resolve();
            });
        });
    },
    updateDriverHasPaid(_, { entity, data }) {
        return new Promise((resolve) => {
            OrderItemRequest.updateDriverHasPaid(entity.id, data).then(() => {
                resolve();
            });
        });
    },
    index({ commit }, filter) {
        ReportRequest.getDriversSummaryReport(filter)
                        .then((data) => {
                            commit('SET_REPORTS', data);
                        });
    },

    getDriversSummaryTotal({ commit }, filter) {
        ReportRequest.getDriversSummaryTotal(filter)
                        .then((data) => {
                            commit('SET_TOTAL', data);
                        });
    },

    getOperations({ commit }) {
        OperationRequest.index().then(({ data }) => {
            commit('SET_OPERATIONS', data);
        });
    },
    getDrivers({ commit }, filter) {
        DriverRequest.index(filter).then(({ data }) => {
            commit('SET_DRIVERS', data);
        });
    },
    getBranches({ commit }) {
        BranchRequest.index()
                    .then(({ data }) => {
                        commit('SET_BRANCHES', data);
                    });
    }
}